import { ReactComponent as WhiteLogo } from '../img/white-logo.svg';

function PlaceholderBanner() {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <div className="logo-space-plc">
          <WhiteLogo className="btss-white-logo-plh" />
          <h1 className="btss-placeholder-brand">by the sea services</h1>
        </div>
        <p className="hero-desc">... gone fishing.</p>
      </div>
    </div>
  );
}

export default PlaceholderBanner;
