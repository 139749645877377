import './App.css';
import PlaceholderBanner from './components/PlaceholderBanner';

function App() {
  return (
    <div className="App">
      {/* <div className="hero-backing"></div> */}
      {/* <Header /> */}
      <PlaceholderBanner />
      {/* <HeroBanner /> */}
      {/* <AboutUs />
      <OurServices />
      <Testimonials />
      <ContactUs />
      <Footer /> */}
    </div>
  );
}

export default App;
